.hbonlogo{
    max-height:40px;
}
.home-container{
    margin-top:200px;
}
.home-image1{
    max-width: -webkit-fill-available;
}
.ql-editor{
    height: 400px;
}
@media (max-width:768px){
    .hbonlogo{
        height:20px;
    }
    .home-container{
        margin-top:20px;
    }
}
